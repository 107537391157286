import api from '@/api/http'

class SellersService {
    root = 'sellers';

    list(params) {
      return this.invoke('', params)
    }

    export(params) {
      return this.invoke('export', params)
    }

    invoke(action, params) {
      return api.get(`/${this.root}/${action}`, { params }).then(({ data }) => data.payload)
    }
}

export default new SellersService()
