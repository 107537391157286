import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import trends from './trends'
import sales from './sales'
import dashboard from './dashboard'
import inventory from './inventory'
import sellers from './sellers'
import user from './user'
import files from './files'
import product from './product'
import subscription from './subscription'
import systemSettings from './system-settings'
import invitationRequests from './invitation-requests'
import profitAndLoss from './profit-and-loss'
import shop from './shop'
import contactUs from './contact-us'
import { isLocal } from '@/utils/utils'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    trends,
    sales,
    dashboard,
    inventory,
    sellers,
    user,
    files,
    product,
    subscription,
    systemSettings,
    invitationRequests,
    shop,
    profitAndLoss,
    contactUs,
  },
  strict: isLocal(),
})
