/* eslint-disable */
import TokenService from './token.service'
import api from './http'

class AuthService {
  login({ email, password, rememberMe, recaptcha }) {
    return api
      .post('/auth/sign-in', {
        email,
        password,
        recaptcha,
        remember_me: rememberMe
      })
      .then(({data}) => {
        if (data.payload && data.payload.access_token) {
          TokenService.setAccessTokens(data.payload)
        }

        return data
      }).catch(error => {
          if (error.message === 'Network Error') {
            return Promise.reject({ message: 'Service Unavailable. Please try later.'})
          }
          const { data } = error.response;
          return Promise.reject(data)
        })
  }

  logout() {
    TokenService.removeUser()
    TokenService.removeAccessTokens()
    TokenService.removeImpersonationAccessTokens()
  }

  register({ email, password, code, recaptcha, first_name, last_name, company_name, referrer_code }) {
    return api.post('/auth/sign-up', {
      email,
      password,
      code,
      recaptcha,
      first_name,
      last_name,
      company_name,
      referrer_code
    })
  }

  requestInvitation({ email, recaptcha }) {
    return api.post('/auth/request-invitation', {
      email,
      recaptcha,
    })
  }

  forgotPassword({ email, recaptcha }) {
    return api.post('/auth/forgot-password', { email, recaptcha })
  }

  passwordReset({ token, newPassword} ) {
    return api.post('/auth/password-reset', {
      token,
      new_password: newPassword
    })
  }

  confirm({ token} ) {
    return api.post('/auth/confirm-email', {
      token
    })
  }

  impersonate(userId) {
    return new Promise((resolve, reject) => {
      api.post('/auth/impersonate', {
        user_id: userId
      }).then(({ data }) => resolve(data.payload || undefined))
          .catch(error => reject(error))
    })
  }

  async checkCode(code) {
      return api.get('/invitation-request/check-invitation-code', {
        params: {
          code
        }
      }).then(({ data }) => data.payload).catch(error => Promise.reject(error))
  }
}

export default new AuthService()
