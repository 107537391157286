import InventoryService from '../../api/inventory.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchList({ commit }, params) {
      return InventoryService.list(params)
    },
    // eslint-disable-next-line no-unused-vars
    export({ commit }, params) {
      return InventoryService.export(params)
    },
    // eslint-disable-next-line no-unused-vars
    updateNote({ commit }, params) {
      return InventoryService.updateNote(params)
    },
    // eslint-disable-next-line no-unused-vars
    updateAlertThreshold({ commit }, params) {
      return InventoryService.updateAlertThreshold(params)
    },
    // eslint-disable-next-line no-unused-vars
    getFirstRowInventorySettingsFile({ commit }, params) {
      return InventoryService.getFirstRowInventorySettingsFile(params)
    },
    // eslint-disable-next-line no-unused-vars
    importSettings({ commit }, params) {
      return InventoryService.importSettings(params)
    },
    // eslint-disable-next-line no-unused-vars
    listImportSettingsRequest({ commit }, params) {
      return InventoryService.listImportSettingsRequest(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchDailyInventory({ commit }, params) {
      return InventoryService.fetchDailyInventory(params)
    },
    // eslint-disable-next-line no-unused-vars
    updateAdditionalData({ commit }, payload) {
      return InventoryService.updateAdditionalData(payload)
    },
  },
}
