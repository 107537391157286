/* eslint-disable */
import api from './http'
import BaseService from "@/api/base.service";

class ContactUsService extends BaseService {
    constructor() {
        super({ root: 'contact-us', api });
    }

    findAll(payload) {
        return this.api.get(
            `/${this.root}/internal/list`,
            { params: { ...payload } },
        ).then(({ data }) => data.payload)
    }

    sendMessage(payload) {
        return this.api.post(
            `/${this.root}/internal/send`,
            payload,
        ).then(({ data }) => data.payload).catch((error) => {
            throw error.response.data
        });
    }
}

export default new ContactUsService();
