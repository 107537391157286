import SalesService from '../../api/sales.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchSales({ commit }, params) {
      return SalesService.fetchSales(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchSalesField({ commit }, params) {
      return SalesService.fetchSalesField(params)
    },

    // eslint-disable-next-line no-unused-vars
    fetchMarketplaceTotals({ commit }, params) {
      return SalesService.fetchMarketplaceTotals(params)
    },

    // eslint-disable-next-line no-unused-vars
    fetchSalesDetails({ commit }, params) {
      return SalesService.fetchSalesDetails(params)
    },

    // eslint-disable-next-line no-unused-vars
    fetchOrders({ commit }, params) {
      return SalesService.fetchOrders(params)
    },

    // eslint-disable-next-line no-unused-vars
    fetchFeeDetails({ commit }, params) {
      return SalesService.fetchFeeDetails(params)
    },

    // eslint-disable-next-line no-unused-vars
    fetchChartData({ commit }, params) {
      return SalesService.fetchChartData(params)
    },

    // eslint-disable-next-line no-unused-vars
    fetchForecastData({ commit }, params) {
      return SalesService.fetchForecastData(params)
    },

    // eslint-disable-next-line no-unused-vars
    export({ commit }, params) {
      return SalesService.export(params)
    },

    // eslint-disable-next-line no-unused-vars
    forecast({ commit }, payload) {
      return SalesService.forecast(payload)
    },
  },
}
