import FilesService from '@/api/files.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    upload({ commit }, file) {
      return FilesService.upload(file)
    },

    // eslint-disable-next-line no-unused-vars
    download({ commit }, payload) {
      return FilesService.download(payload)
    },
  },
}
