/* eslint-disable no-unused-vars */
import Service from '@/api/contact-us.service'

export default {
  namespaced: true,
  actions: {
    fetchList({ commit }, payload) {
      return Service.findAll(payload)
    },
    sendMessage({ commit }, payload) {
      return Service.sendMessage(payload)
    },
  },
}
