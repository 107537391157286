<template>
  <div
    v-if="!isFullLayout && isUserLoggedIn && isTrial && isPanelVisible"
    class="trial-panel d-flex justify-content-center"
  >
    <div class="trial-panel-content">
      Trial period ends {{ formatDate(trialPeriodEndDate) }} - <strong
        v-if="!isSubscribeLoading"
        class="cursor-pointer"
        @click="onSubscribeClick"
      >subscribe now.</strong>
      <span
        v-else
        class="spinner-wrapper"
      >
        <BSpinner
          small
          type="grow"
        />
        <BSpinner
          small
          type="grow"
        />
        <BSpinner
          small
          type="grow"
        />
      </span>
      <b-button
        v-if="!isMobile"
        v-b-tooltip.hover
        class="ml-50"
        title="Click to hide for 15 minutes"
        variant="outline-secondary"
        size="xs"
        @click="onHideClick"
      >
        <FeatherIcon icon="XIcon" />
      </b-button>
      <span
        v-else
        class="hide-button"
        @click="onHideClick"
      >
        Click to hide for 15 minutes
      </span>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner, VBTooltip } from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { isUserLoggedIn } from '@/auth/utils'
import EventBus from '@/utils/event-bus'

export default {
  name: 'TrialPanel',
  components: {
    BSpinner,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: undefined,
      isLoading: true,
      debouncedAction: undefined,
      isSubscribeLoading: false,
      popupWindow: undefined,
      isPanelVisible: true,
    }
  },
  computed: {
    isFullLayout() {
      return this.$route.meta.layout === 'full'
    },
    isUserLoggedIn() {
      return isUserLoggedIn()
    },
    isTrial() {
      return this.$store.getters['auth/isTrial']
    },
    trialPeriodEndDate() {
      return this.$store.getters['auth/trialPeriodEndDate']
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isMobile() {
      return this.currentBreakPoint === 'sm' || this.currentBreakPoint === 'xs'
    },
  },
  mounted() {
    EventBus.$on('ws_subscription_created', this.onSubscribed)
    this.$store.dispatch('subscription/fetchAvailableSubscription').then(result => {
      this.data = result
    }).finally(() => {
      this.isLoading = false
    })

    const hideUntil = localStorage.getItem('hideUntil')

    if (hideUntil) {
      const currentTime = Date.now()
      const hideUntilTime = parseInt(hideUntil, 10)

      if (currentTime < hideUntilTime) {
        this.isPanelVisible = false
        setTimeout(() => {
          localStorage.removeItem('hideUntil')
          this.isPanelVisible = true
        }, hideUntilTime - currentTime)
      } else {
        localStorage.removeItem('hideUntil')
      }
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).utc().format('MMM D, YYYY h:mm A')
    },
    onSubscribeClick() {
      if (this.debouncedAction) clearTimeout(this.debouncedAction)
      this.debouncedAction = setTimeout(() => {
        this.isSubscribeLoading = true
        this.$store.dispatch('subscription/subscribe', { price_id: this.data.id }).then(result => {
          const width = 700
          const height = 750
          const left = (window.screen.availWidth / 2) - (width / 2)
          const top = (window.screen.availHeight / 2) - (height / 2)
          const features = `location=yes,height=${height},width=${width},scrollbars=yes,status=yes,left=${left},top=${top}screenX=${left},screenY=${top}`
          this.popupWindow = window.open(result, '_blank', features)
        }).finally(() => {
          this.isSubscribeLoading = false
        })
      }, 500)
    },
    onSubscribed() {
      this.$store.commit('auth/trial', false)
      if (this.popupWindow) {
        setTimeout(() => { this.popupWindow.close() }, 5000)
      }
    },
    onHideClick() {
      const hideUntil = Date.now() + 15 * 60 * 1000
      localStorage.setItem('hideUntil', hideUntil.toString())
      this.isPanelVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
  .trial-panel {
    text-align: center;
    z-index: 1000;
    position: fixed;
    bottom: -5px;
    left: 0;
    width: 100%;

    .trial-panel-content {
      padding: 0.5rem 1.5rem;
      background-image: linear-gradient(47deg, #ff9f43, #ffb976);
      color: white;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      .btn-outline-secondary {
        color: white !important;
        border-color: white !important;
        &:hover {
          color: white;
          background-color: rgba(255, 255, 255, 0.1);
          border-color: white;
        }
      }
      .hide-button {
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }

    @media (max-width: 575.98px) {
      .trial-panel-content {
        padding: 0.5rem 0;
      }
    }

    .spinner-wrapper {
      span {
        vertical-align: baseline;
        margin-right: 0.3rem;
        &.spinner-grow-sm {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }
  }
</style>
