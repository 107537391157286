import InvitationRequestsService from '@/api/invitation-requests.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchList({ commit }, payload) {
      return InvitationRequestsService.fetchList(payload)
    },
    // eslint-disable-next-line no-unused-vars
    approve({ commit }, payload) {
      return InvitationRequestsService.approve(payload)
    },
    // eslint-disable-next-line no-unused-vars

  },
}
