export default [
  /**
   * New Pages
   */
  {
    path: '/profit-and-lost',
    name: 'p&l-overview',
    component: () => import('@/views/pages/profit-and-lost/overview/PLOverview.vue'),
  },
  {
    path: '/profit-and-lost/breakdown',
    name: 'p&l-breakdown',
    component: () => import('@/views/pages/profit-and-lost/breakdown/PLBreakdown.vue'),
  },
  {
    path: '/profit-and-lost/transaction',
    name: 'p&l-transaction',
    component: () => import('@/views/pages/profit-and-lost/transaction/PLTransaction.vue'),
  },
  {
    path: '/orders/by-asin',
    name: 'orders-by-asin',
    component: () => import('@/views/pages/orders/by-asin/OrdersByAsin.vue'),
  },
  {
    path: '/orders/by-recency',
    name: 'orders-by-recency',
    component: () => import('@/views/pages/orders/by-recency/OrdersByRecency.vue'),
  },
  {
    path: '/sales/velocity-trends',
    name: 'sales-velocity-trends',
    component: () => import('@/views/pages/sales/velocity-trends/SalesVelocityTrends.vue'),
  },
  {
    path: '/sales/forecasts',
    name: 'sales-forecasts',
    component: () => import('@/views/pages/sales/forecasts/SalesForecasts.vue'),
  },
  /**
   * END New Pages
   */
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      availableForNotConnected: true,
    },
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/sales/:timeFilter?',
    name: 'sales',
    props: true,
    component: () => import('@/views/sales_v2/Sales.vue'),
    meta: {
      resource: 'Sales',
      action: 'read',
    },
  },
  {
    path: '/trends',
    name: 'trends',
    component: () => import('@/views/trends/Trends.vue'),
    meta: {
      resource: 'Trends',
      action: 'read',
    },
  },
  {
    path: '/profit-loss',
    name: 'profit_loss',
    component: () => import('@/views/profit-and-loss/ProfitAndLoss.vue'),
    meta: {
      resource: 'ProfitLoss',
      action: 'read',
    },
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('@/views/inventory-v2/Inventory.vue'),
    meta: {
      resource: 'Inventory',
      action: 'read',
    },
  },
  {
    path: '/daily-inventory',
    name: 'daily_inventory',
    component: () => import('@/views/fba-inventory/FBAInventory.vue'),
    meta: {
      resource: 'Inventory',
      action: 'read',
    },
  },
  {
    path: '/settings/products-configuration',
    name: 'products-configuration',
    component: () => import('@/views/settings/products/ProductsConfiguration.vue'),
  },
  {
    path: '/sellers',
    name: 'sellers',
    component: () => import('@/views/sellers/Sellers.vue'),
    // component: () => import('@/views/Sellers.vue'),
    meta: {
      resource: 'Sellers',
      action: 'read',
    },
  },

  // Account Setting
  {
    path: '/profile/:activeTab?',
    name: 'account-setting',
    props: true,
    meta: {
      availableForNotConnected: true,
    },
    component: () => import('@/views/account-setting/AccountSetting.vue'),
  },
  {
    path: '/subscription/success',
    name: 'subscription-success',
    component: () => import('@/views/subscription/PaymentSuccess.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/subscription/failed',
    name: 'subscription-failed',
    component: () => import('@/views/subscription/PaymentFailed.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/payment-method/success',
    name: 'payment-method-success',
    component: () => import('@/views/subscription/PaymentMethodSuccess.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/payment-method/failed',
    name: 'payment-method-failed',
    component: () => import('@/views/subscription/PaymentMethodFailed.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/marketplace-connected',
    name: 'marketplace-connected',
    component: () => import('@/views/MarketplaceConnected.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      availableForNotConnected: true,
    },
  },
  // Auth
  {
    path: '/sign-in',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
      availableForNotConnected: true,
    },
  },
  {
    path: '/sign-up/:code?',
    props: true,
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
      availableForNotConnected: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
      availableForNotConnected: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    props: true,
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
      availableForNotConnected: true,
    },
  },
  {
    path: '/verify-email/:token',
    name: 'verify-password',
    props: true,
    component: () => import('@/views/VerifyEmail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
      availableForNotConnected: true,
    },
  },
  // Admin section
  {
    path: '/users/manage',
    name: 'admin-manage-users',
    meta: {
      resource: 'Users',
      action: 'write',
    },
    component: () => import('@/views/admin/users/ManageUsers.vue'),
  },
  {
    path: '/shops/manage',
    name: 'admin-manage-shops',
    meta: {
      resource: 'Users',
      action: 'write',
    },
    component: () => import('@/views/admin/shops/ManageShops.vue'),
  },
  {
    path: '/invitation-requests/manage',
    name: 'admin-manage-invitation-requests',
    meta: {
      resource: 'InvitationRequests',
      action: 'write',
    },
    component: () => import('@/views/admin/invitation-requests/ManageInvitationRequests.vue'),
  },
  {
    path: '/system-settings',
    name: 'system-settings',
    meta: {
      resource: 'Users',
      action: 'write',
    },
    component: () => import('@/views/admin/settings/SystemSettings.vue'),
  },
  {
    path: '/contact-us/manage',
    name: 'contact-us-manage',
    meta: {
      resource: 'Users',
      action: 'write',
    },
    component: () => import('@/views/admin/contact-us/ManageContactUs.vue'),
  },
  {
    path: '/tools/forecast',
    name: 'forecast-tool',
    meta: {
      resource: 'Users',
      action: 'write',
    },
    component: () => import('@/views/admin/forecast/Forecast.vue'),
  },
  {
    path: 'forbidden',
    name: 'misc-not-authorized',
    component: () => import('@/views/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      availableForNotConnected: true,
    },
  },
  {
    path: '/static',
    name: 'static',
    component: () => import('@/views/Static.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
      availableForNotConnected: true,
    },
  },
  {
    path: '*',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      availableForNotConnected: true,
    },
  },
]
