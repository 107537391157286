import axios from 'axios'
import { apiBaseUrl } from '@/utils/utils'

const instance = axios.create({
  baseURL: apiBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
})
export default instance
