/* eslint-disable */
import api from './http'
import TokenService from "@/api/token.service";
import BaseService from "@/api/base.service";

class UserService extends BaseService {
    constructor() {
        super({ root: 'user', api });
    }

    userData() {
        return api
            .get('/user/profile')
            .then(({data}) => {
                if (data.payload) {
                    TokenService.setUser(data.payload)
                }

                return data.payload
            })
    }

    changePassword({ oldPassword, newPassword }) {
        return this.invoke(
            'change-password',
            {
                old_password: oldPassword,
                new_password: newPassword
            },
            'post'
        )
    }

    approveSignUp({ userId }) {
        return this.invoke(
            'approve-sign-up',
            {
                user_id: userId,
            },
            'post'
        )
    }

    sendShopNotConnectedReminder({ userId }) {
        return this.invoke(
            'send-shop-not-connected-reminder',
            {
                user_id: userId,
            },
            'post'
        )
    }

    updateProfile({ firstName, lastName, timezone, file, currency, companyName }) {

        const body = new FormData();

        body.append('first_name', firstName)
        body.append('last_name', lastName)
        body.append('timezone', timezone)
        body.append('currency', currency)
        body.append('company_name', companyName)

        if (file) {
            body.append('file', file)
        }

        return this.api.request({
            url: `/${this.root}/update-profile`,
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: body,
        }).then(({ data }) => data.payload)
    }

    updateSettings({ index, payload }) {
        return this.invoke(
            'update-settings',
            {
                index,
                payload,
            },
            'post'
        )
    }

    getSettings(key) {
        return this.api.get(
            `/${this.root}/settings-by-key`,
            { params: { key } },
        ).then(({ data }) => data.payload)
    }

    getShopStatus() {
        return this.api.get(
            `/shops/status`,
        ).then(({ data }) => data.payload)
    }

    getShopSyncStatus() {
        return this.api.get(
            `/shops/sync-status`,
        ).then(({ data }) => data.payload)
    }

    getList(payload) {
        return this.api.get(
            `/${this.root}/list`,
            { params: { ...payload } },
        ).then(({ data }) => data.payload)
    }

    find(payload) {
        return this.api.get(
            `/${this.root}/find`,
            { params: { ...payload } },
        ).then(({ data }) => data.payload)
    }

    fetchLedgers(payload) {
        return this.api.get(
            `/${this.root}/ledgers`,
            { params: { ...payload } },
        ).then(({ data }) => data.payload)
    }
}

export default new UserService();
