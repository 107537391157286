/* eslint-disable */
class TokenService {

  setAccessTokens({ access_token, refresh_token }) {
    localStorage.setItem('id_token', access_token)
    localStorage.setItem('refresh_token', refresh_token)
  }

  setImpersonationAccessTokens({ access_token, refresh_token }) {
    localStorage.setItem('imp_id_token', access_token)
    localStorage.setItem('imp_refresh_token', refresh_token)
  }

  removeImpersonationAccessTokens() {
    localStorage.removeItem('imp_id_token')
    localStorage.removeItem('imp_refresh_token')
  }

  getLocalRefreshToken() {
    let token = localStorage.getItem('imp_refresh_token');
    if (!token) {
      token = localStorage.getItem('refresh_token');
    }
    return token;
  }

  getLocalAccessToken() {
    let token = localStorage.getItem('imp_id_token');
    if (!token) {
      token = localStorage.getItem('id_token');
    }
    return token;
  }

  updateLocalAccessToken(token) {
    localStorage.setItem('id_token', token)
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  removeUser() {
    localStorage.removeItem('user')
  }

  removeAccessTokens() {
    localStorage.removeItem('id_token')
    localStorage.removeItem('refresh_token')
  }
}

export default new TokenService()
