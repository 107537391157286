import api from '@/api/http'

class ProfitAndLossService {
    root = 'profit-and-loss';

    /**
     * GET /profit-and-loss/get-data
     * @param params
     */
    fetchData(params) {
      return api.request({
        url: `/${this.root}/get-data`,
        method: 'GET',
        params,
      }).then(({ data }) => data.payload)
    }
}

export default new ProfitAndLossService()
