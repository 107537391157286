import api from './http'

class SalesService {
    root = 'sales';

    fetchSales(params) {
      return this.invoke('list', params)
    }

    fetchSalesField(params) {
      return this.invoke('distinct-field-values', params)
    }

    fetchMarketplaceTotals(params) {
      return this.invoke('marketplace-totals', params)
    }

    fetchSalesDetails(params) {
      return this.invoke('sales-details', params)
    }

    fetchChartData(params) {
      return this.invoke('chart-data', params)
    }

    fetchFeeDetails(params) {
      return this.invoke('fee-details', params)
    }

    fetchOrders(params) {
      return this.invoke('orders', params)
    }

    fetchForecastData(params) {
      return this.invoke('forecast', params)
    }

    forecast(params) {
      return this.invoke('forecast-tool', params)
    }

    export(params) {
      return api.get(`/${this.root}/export`, { params }).then(({ data, headers }) => ({
        data,
        headers,
      }))
    }

    invoke(action, params) {
      return api.get(`/${this.root}/${action}`, { params }).then(({ data }) => data.payload)
    }
}

export default new SalesService()
