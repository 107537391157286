import DashboardService from '../../api/dashboard.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchSales({ commit }, params) {
      return DashboardService.sales(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchSalesGrowth({ commit }, params) {
      return DashboardService.salesGrowth(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchMargin({ commit }, params) {
      return DashboardService.margin(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchRecordProfit({ commit }, params) {
      return DashboardService.recordProfit(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchRecordUnitSold({ commit }, params) {
      return DashboardService.recordUnitSold(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchTopSellers({ commit }, params) {
      return DashboardService.topSellers(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchInventoryStock({ commit }, params) {
      return DashboardService.inventoryStock(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchInventoryStatus({ commit }, params) {
      return DashboardService.inventoryStatus(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchSellersActivity({ commit }, params) {
      return DashboardService.sellersActivity(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchSalesForecast({ commit }) {
      return DashboardService.salesForecast()
    },
    // eslint-disable-next-line no-unused-vars
    fetchSalesTrends({ commit }, params) {
      return DashboardService.salesTrends(params)
    },
  },
}
