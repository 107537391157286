import TrendsService from '../../api/trends.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchOrders({ commit }, params) {
      return TrendsService.orders(params)
    },

    // eslint-disable-next-line no-unused-vars
    fetchSales({ commit }, params) {
      return TrendsService.sales(params)
    },

    // eslint-disable-next-line no-unused-vars
    fetchProfit({ commit }, params) {
      return TrendsService.profit(params)
    },

    // eslint-disable-next-line no-unused-vars
    fetchRevenue({ commit }, params) {
      return TrendsService.revenue(params)
    },
  },
}
