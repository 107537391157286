import SystemSettingsService from '@/api/system-settings.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchSettings({ commit }) {
      return SystemSettingsService.getSettings()
    },
    // eslint-disable-next-line no-unused-vars
    saveStripeSettings({ commit }, payload) {
      return SystemSettingsService.saveStripeSettings(payload)
    },
    // eslint-disable-next-line no-unused-vars
    saveGeneralSettings({ commit }, payload) {
      return SystemSettingsService.saveGeneralSettings(payload)
    },
    // eslint-disable-next-line no-unused-vars
    fetchAppConf({ commit }, payload) {
      return SystemSettingsService.fetchAppConf(payload)
    },
    // eslint-disable-next-line no-unused-vars
    saveSettingsByKey({ commit }, payload) {
      return SystemSettingsService.saveSettingsByKey(payload)
    },
  },
}
