import AuthService from '../../api/auth.service'
import UserService from '../../api/user.service'
import TokenService from '@/api/token.service'

const identity = JSON.parse(localStorage.getItem('user'))
const impersonated = JSON.parse(localStorage.getItem('impersonated'))
const initialState = identity
  ? {
    status: { loggedIn: true }, impersonated, identity, loginFormSuccessMessage: null,
  }
  : {
    status: { loggedIn: false }, impersonated, identity: null, loginFormSuccessMessage: null,
  }

export default {
  namespaced: true,
  state: initialState,
  actions: {
    // eslint-disable-next-line no-unused-vars
    async checkCode({ commit }, { code }) {
      const payload = await AuthService.checkCode(code)
      return payload
    },
    async login({ commit }, user) {
      const accessToken = await AuthService.login(user)

      const userData = await UserService.userData().then(
        response => {
          commit('loginSuccess', response)
          commit('app/UPDATE_AVAILABLE_MARKETPLACES', response.marketplaces, { root: true })
          return Promise.resolve(response)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        },
      )

      return { accessToken, userData }
    },
    getUserData({ commit }) {
      UserService.userData().then(
        response => {
          commit('updateIdentity', response)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        },
      )
    },
    logout({ commit }) {
      AuthService.logout()
      localStorage.removeItem('user')
      localStorage.removeItem('impersonated')
      commit('logout')
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        },
      )
    },
    requestInvitation({ commit }, payload) {
      return AuthService.requestInvitation(payload).then(
        response => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        },
      )
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken)
    },
    // eslint-disable-next-line no-unused-vars
    forgotPassword({ commit }, payload) {
      return AuthService.forgotPassword(payload).then(
        ({ data }) => Promise.resolve(data.payload),
        error => Promise.reject(error),
      )
    },
    // eslint-disable-next-line no-unused-vars
    passwordReset({ commit }, payload) {
      return AuthService.passwordReset(payload).then(
        response => Promise.resolve(response.data),
        error => Promise.reject(error),
      )
    },
    // eslint-disable-next-line no-unused-vars
    confirm({ commit }, payload) {
      return AuthService.confirm(payload).then(
        response => Promise.resolve(response.data),
        error => Promise.reject(error),
      )
    },
    // eslint-disable-next-line no-unused-vars
    async impersonate({ commit }, id) {
      const accessToken = await AuthService.impersonate(id)
      TokenService.setImpersonationAccessTokens(accessToken)
      const userData = await UserService.userData().then(
        response => {
          commit('impersonationSuccess', response)
          commit('app/UPDATE_AVAILABLE_MARKETPLACES', response.marketplaces, { root: true })
          return Promise.resolve(response)
        },
        error => {
          TokenService.removeImpersonationAccessTokens()
          Promise.reject(error)
        },
      )

      return userData
    },
    stopImpersonation({ commit }) {
      TokenService.removeImpersonationAccessTokens()
      commit('stopImpersonation')
    },
  },
  getters: {
    userId: state => (state.identity ? state.identity.uuid : undefined),
    userGeneral: state => () => ({
      firstName: state.identity?.first_name ?? undefined,
      lastName: state.identity?.last_name ?? undefined,
      timezone: state.identity?.timezone ?? undefined,
      avatar: state.identity?.avatar ?? undefined,
      currency: state.identity?.currency ?? undefined,
      companyName: state.identity?.company_name ?? undefined,
      referralCode: state.identity?.referral_code ?? undefined,
      balance: state.identity?.balance ?? 0,
    }),
    userAvatar: state => () => state.identity?.avatar ?? undefined,
    isAdmin: state => () => ['root', 'admin'].includes(state?.identity?.role) ?? undefined,
    userFullName: state => () => {
      if (!state.identity || !state.identity.first_name) {
        return 'Shop Owner'
      }
      const fname = state.identity.first_name ?? undefined
      const lname = state.identity.last_name ?? undefined
      return `${fname} ${lname}`
    },
    companyName: state => () => state.identity?.company_name ?? 'Your Company',
    isImpersonated: state => !!state.impersonated,
    isStoreConnected: state => state.identity?.connected ?? false,
    isSynchronized: state => state.identity?.synchronized ?? false,
    isSubscribed: state => state.identity.subscription,
    isTrial: state => state.identity?.trial ?? false,
    trialPeriodEndDate: state => state.identity?.trial_period_end_date ?? '',
    loginFormSuccessMessage: state => state.loginFormSuccessMessage,

  },
  mutations: {
    impersonationSuccess(state, userData) {
      localStorage.setItem('impersonated', JSON.stringify(userData))
    },
    stopImpersonation(state) {
      state.impersonated = undefined
      localStorage.removeItem('impersonated')
    },
    updateUserProfile(state, {
      firstName, lastName, timezone, avatar, companyName,
    }) {
      state.identity.first_name = firstName
      state.identity.last_name = lastName
      state.identity.timezone = timezone
      state.identity.avatar = avatar
      state.identity.company_name = companyName
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(state.identity))
    },
    updateIdentity(state, user) {
      state.user = user
      state.identity = JSON.parse(localStorage.getItem('user'))
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
      state.identity = JSON.parse(localStorage.getItem('user'))
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
      state.identity = null
      state.impersonated = undefined
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true
      state.user = { ...state.user, accessToken }
    },
    synchronized(state) {
      state.identity.connected = true
      state.identity.synchronized = true
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(state.identity))
    },
    connected(state) {
      state.identity.connected = true
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(state.identity))
    },
    subscription(state, value) {
      state.identity.subscription = value
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(state.identity))
    },
    trial(state, value) {
      state.identity.trial = value
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(state.identity))
    },
    updateLoginFormSuccessMessage(state, value) {
      state.loginFormSuccessMessage = value
    },
    resetLoginFormSuccessMessage(state) {
      state.loginFormSuccessMessage = null
    },
  },
}
