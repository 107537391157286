import api from '@/api/http'

class InventoryService {
    root = 'inventory';

    list(params) {
      return this.invoke('', params)
    }

    export(params) {
      return api.get(`/${this.root}/export`, { params }).then(({ data, headers }) => ({
        data,
        headers,
      }))
    }

    updateAlertThreshold(params) {
      return api.post(`/${this.root}/update-alert-threshold`, params).then(({ data }) => data.payload || undefined)
    }

    updateNote(params) {
      return new Promise((resolve, reject) => {
        api.post(`/${this.root}/update-note`, params)
          .then(({ data }) => resolve(data.payload || undefined))
          .catch(error => reject(error))
      })
    }

    importSettings(params) {
      return new Promise((resolve, reject) => {
        api.post(`/${this.root}/import-settings`, params)
          .then(({ data }) => resolve(data.payload || undefined))
          .catch(error => reject(error))
      })
    }

    getFirstRowInventorySettingsFile(params) {
      return this.invoke('get-first-row-inventory-settings-file', params).then(data => data)
    }

    invoke(action, params) {
      return api.get(`/${this.root}/${action}`, { params }).then(({ data }) => data.payload)
    }

    listImportSettingsRequest(params) {
      return this.invoke('list-import-settings-request', params).then(data => data)
    }

    fetchDailyInventory(params) {
      return api.post(`/${this.root}/daily-inventory`, params).then(({ data }) => data.payload)
      // return this.invoke('daily-inventory', { ...params, filter }).then(data => data)
    }

    updateAdditionalData(payload) {
      return api.post(`/${this.root}/update-additional-data`, payload).then(({ data }) => data.payload)
    }
}

export default new InventoryService()
