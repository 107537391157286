import api from './http'

class TrendsService {
    root = 'trends';

    orders(params) {
      return this.invoke('orders', params)
    }

    sales(params) {
      return this.invoke('sales', params)
    }

    profit(params) {
      return this.invoke('profit', params)
    }

    revenue(params) {
      return this.invoke('revenue', params)
    }

    invoke(action, params) {
      return api.get(`/${this.root}/${action}`, params).then(({ data }) => data.payload)
    }
}

export default new TrendsService()
