import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import apps from './routes/apps'
import pages from './routes/pages'
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
    },
    {
      path: '/app/',
      redirect: 'dashboard',
    },
    ...apps,
    ...pages,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  if ((!store.getters['auth/isStoreConnected'] || !store.getters['auth/isSynchronized'] || !store.getters['auth/isSubscribed']) && !to.meta.availableForNotConnected) {
    return next({ name: 'dashboard' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  if (process.env.NODE_ENV === 'production') {
    // Google Analytics
    if (window.GA_TRACKING_ID !== null && window.GA_TRACKING_ID !== undefined) {
      // eslint-disable-next-line no-undef
      gtag('config', window.GA_TRACKING_ID, {
        page_path: to.fullPath,
        app_name: 'Sellerxray Dashboard App',
        send_page_view: true,
      })
    }
  }
})

export default router
