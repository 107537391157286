<template>
  <b-modal
    v-model="isVisible"
    size="lg"
    title="Product Configuration"
    body-class="tw-p-0"
  >
    <DxScrollView
      :height="modalHeight"
    >
      <div class="tw-flex tw-flex-col tw-gap-2 tw-pr-8 tw-pl-8 tw-pt-2">
        <ProductDetails
          :asin="productAsin"
          :image="productImage"
          :sku="productSku"
          :short-name="productShortName"
          :marketplace="productMarketplace"
        />
        <div class="tw-flex tw-flex-row">
          <b-form-group
            v-slot="{ ariaDescribedby }"
            class="mt-1"
          >
            <b-form-radio-group
              v-model="form.fulfilmentBy"
              :options="fulfilmentOptions"
              :aria-describedby="ariaDescribedby"
              name="fulfilment_by"
            />
          </b-form-group>
        </div>
        <div class="tw-flex tw-flex-col lg:tw-flex-row cost-form">
          <div
            class="d-flex flex-column flex-1 pr-1 pb-1 pb-sm-0 pb-md-0 pb-lg-0 pb-xl-0"
            :class="{'border-bottom': isCurrentBreakPointXs, 'width-60': !isCurrentBreakPointXs}"
          >
            <div class="tw-flex tw-justify-between border-bottom tw-pb-1">
              <div class="text-bold">
                Enter COGS (USD)
              </div>
            </div>
            <div
              v-if="isAdvancedView"
              class="d-flex flex-column"
            >
              <div
                v-if="showRange"
                class="tw-flex tw-flex-row tw-gap-4 tw-items-center tw-pt-2"
              >
                <DxDateBox
                  v-model="form.startDate"
                  :use-mask-behavior="true"
                  width="200"
                  height="34"
                  display-format="shortdate"
                  type="date"
                  placeholder="Start Date"
                  :max="maxDate"
                  :show-clear-button="true"
                  :read-only="isFormReadOnly"
                />
                <div> - </div>
                <DxDateBox
                  v-model="form.endDate"
                  :use-mask-behavior="true"
                  width="200"
                  height="34"
                  display-format="shortdate"
                  type="date"
                  placeholder="End Date"
                  :min="minDate"
                  :show-clear-button="true"
                  :read-only="isFormReadOnly"
                />
              </div>
              <div
                v-else
                class="tw-pb-2 tw-pt-2"
              >
                <BButton
                  size="sm"
                  variant="outline-primary"
                  @click="showRange = true"
                >
                  Show Date Range
                </BButton>
              </div>
              <div class="d-flex flex-row mt-50 align-items-center">
                <div class="d-flex form-label">
                  Sales/VAT tax
                </div>
                <feather-icon
                  v-b-tooltip.hover="'If your sales price includes sales tax, enable Shopkeeper to deduct tax from your revenues.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row align-items-center">
                  <div class="checkbox-wrapper">
                    <DxCheckBox
                      v-model="form.salesVatTaxEnabled"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                  <div class="input-wrapper">
                    <DxNumberBox
                      v-model="form.salesVatTax"
                      :step="0.0001"
                      :min="0"
                      :max="100"
                      format="#0.00%"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row mt-50 align-items-center">
                <div class="d-flex form-label">
                  Offset VAT on fees
                </div>
                <feather-icon
                  v-b-tooltip.hover="'If Amazon fees include VAT but you can deduct it, tick this to add it back to your profit. Enter VAT rate.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row align-items-center">
                  <div class="bg-green checkbox-wrapper">
                    <DxCheckBox
                      v-model="form.offsetVatOnFeesEnabled"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                  <div class="bg-orange input-wrapper">
                    <DxNumberBox
                      v-model="form.offsetVatOnFees"
                      :step="0.0001"
                      :min="0"
                      :max="100"
                      format="#0.00%"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row mt-50 align-items-center">
                <div class="d-flex form-label">
                  Manufacturing
                </div>
                <feather-icon
                  v-b-tooltip.hover="'Enter total manufacturing cost per unit here.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row">
                  <div class="bg-orange input-wrapper-large">
                    <DxNumberBox
                      v-model="form.manufacturing"
                      :step="0.25"
                      :min="0"
                      format="#,##0.00"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row mt-50 align-items-center">
                <div class="d-flex form-label">
                  Inspection
                </div>
                <feather-icon
                  v-b-tooltip.hover="'Enter product inspection cost per unit.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row">
                  <div class="bg-orange input-wrapper-large">
                    <DxNumberBox
                      v-model="form.inspection"
                      :step="0.25"
                      :min="0"
                      format="#,##0.00"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row mt-50 align-items-center">
                <div class="d-flex form-label">
                  Region Shipping
                </div>
                <feather-icon
                  v-b-tooltip.hover="'Enter shipping costs per unit from manufacturer to destination country.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row">
                  <div class="bg-orange input-wrapper-large">
                    <DxNumberBox
                      v-model="form.regionShipping"
                      :step="0.25"
                      :min="0"
                      format="#,##0.00"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row mt-50 align-items-center">
                <div class="d-flex form-label">
                  Import Tax
                </div>
                <feather-icon
                  v-b-tooltip.hover="'Enter estimated import tax per unit.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row">
                  <div class="bg-orange input-wrapper-large">
                    <DxNumberBox
                      v-model="form.importTax"
                      :step="0.25"
                      :min="0"
                      format="#,##0.00"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row mt-50 align-items-center">
                <div class="d-flex form-label">
                  Other Product Costs
                </div>
                <feather-icon
                  v-b-tooltip.hover="'Enter any additional costs per unit.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row">
                  <div class="bg-orange input-wrapper-large">
                    <DxNumberBox
                      v-model="form.otherProductCosts"
                      :step="0.25"
                      :min="0"
                      format="#,##0.00"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="!isFBM"
                class="d-flex flex-row mt-50 align-items-center"
              >
                <div class="d-flex form-label">
                  Shipping to Amz
                </div>
                <feather-icon
                  v-b-tooltip.hover="'Enter per unit shipping cost if applicable.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row">
                  <div class="bg-orange input-wrapper-large">
                    <DxNumberBox
                      v-model="form.shippingToAmazon"
                      :step="0.25"
                      :min="0"
                      format="#,##0.00"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="isFBM"
                class="d-flex flex-row mt-50 justify-content-between text-bold border-bottom"
              >
                <div>Total FBM COGS</div>
                <div>{{ totalFBMCOGS }}</div>
              </div>
              <div
                v-if="isFBM"
                class="d-flex flex-row mt-50 align-items-center"
              >
                <div class="d-flex form-label">
                  FBM Shipping per unit
                </div>
                <feather-icon
                  v-b-tooltip.hover="'Enter per unit shipping cost if applicable.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row">
                  <div class="bg-orange input-wrapper-large">
                    <DxNumberBox
                      v-model="form.fbmShippingPerUnit"
                      :step="0.25"
                      :min="0"
                      format="#,##0.00"
                      :read-only="isFormReadOnly"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="d-flex flex-column"
            >
              <div class="d-flex flex-row mt-50 align-items-center">
                <div class="d-flex form-label">
                  Total Cost
                </div>
                <feather-icon
                  v-b-tooltip.hover="'Enter any additional costs per unit.'"
                  class="mr-50"
                  icon="HelpCircleIcon"
                />
                <div class="d-flex flex-row">
                  <div class="bg-orange input-wrapper-large">
                    <DxNumberBox
                      v-model="form.otherProductCosts"
                      :step="0.01"
                      :min="0"
                      format="#,##0.00"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EstimateFBAProfit
            :currency="currency"
            class="flex-1 pt-1 pt-sm-0 pt-lg-0 pt-xl-0"
          />
        </div>
        <OtherSKUs
          class="mt-1"
          :asin="productAsin"
          :sku="productSku"
          :marketplace="productMarketplace.marketplace_id"
          @on-selection-changed="onSelectionChanged"
        />
      </div>
    </DxScrollView>

    <template #modal-footer="{ cancel }">
      <div class="tw-flex tw-flex-1 tw-flex-row tw-gap-3 justify-content-between">
        <DxCheckBox
          v-model="isAdvancedView"
          text="Advanced View"
        />
        <div class="tw-flex tw-flex-row tw-gap-3">
          <b-button
            size="sm"
            style="width: 140px"
            variant="success"
            :disabled="isLoading"
            @click="onSaveBtnClick"
          >
            <span v-if="!isLoading">Save</span>
            <span v-else>
              <b-spinner
                class="mr-1"
                small
                variant="light"
              />
              Saving...
            </span>
          </b-button>
          <b-button
            size="sm"
            @click="cancel()"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BButton, BFormGroup, BFormRadioGroup, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { DxCheckBox } from 'devextreme-vue/check-box'
import DxDateBox from 'devextreme-vue/date-box'
import DxNumberBox from 'devextreme-vue/number-box'
import { DxScrollView } from 'devextreme-vue/scroll-view'
import ProductDetails from '@/views/settings/products/components/components/edit/ProductDetails.vue'
import EstimateFBAProfit from '@/views/settings/products/components/components/edit/EstimateFBAProfit.vue'
import OtherSKUs from '@/views/settings/products/components/components/edit/OtherSKUs.vue'

export default {
  name: 'EditModal',
  components: {
    OtherSKUs,
    BButton,
    EstimateFBAProfit,
    ProductDetails,
    BFormGroup,
    BFormRadioGroup,
    DxNumberBox,
    DxCheckBox,
    DxDateBox,
    BSpinner,
    DxScrollView,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    visible: {
      type: Boolean,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    settingsId: {
      type: String,
      default: null,
    },
    advancedView: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currency: 'USD',
      showRange: false,
      fulfilmentOptions: [
        { text: 'FBA', value: 'FBA' },
        { text: 'FBM', value: 'FBM' },
      ],
      isAdvancedView: false,
      form: {
        fulfilmentBy: 'FBA',
        salesVatTax: 0,
        salesVatTaxEnabled: false,
        offsetVatOnFees: 0,
        offsetVatOnFeesEnabled: false,
        manufacturing: 0,
        inspection: 0,
        regionShipping: 0,
        importTax: 0,
        otherProductCosts: 0,
        shippingToAmazon: 0,
        fbmShippingPerUnit: 0,
        startDate: null,
        endDate: null,
        asin: null,
        sku: null,
        marketplace: null,
        otherSkus: [],
      },
    }
  },
  computed: {
    isFormReadOnly() {
      return this.isLoading
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    isFBM() {
      return this.form.fulfilmentBy === 'FBM'
    },
    totalFBMCOGS() {
      const sum = this.form.manufacturing + this.form.inspection + this.form.regionShipping + this.form.importTax + this.form.otherProductCosts + this.form.shippingToAmazon
      return sum.toFixed(2)
    },
    isVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    productAsin() {
      return this.product.asin || ''
    },
    productImage() {
      return this.product.image || undefined
    },
    productSku() {
      return this.product.sku || ''
    },
    productShortName() {
      return this.product.shortName || ''
    },
    productMarketplace() {
      return this.product.marketplace || {}
    },
    modalHeight() {
      return this.currentBreakPoint === 'xs' ? '80vh' : '75vh'
    },
    minDate() {
      if (this.form.startDate) {
        // subtract 1 day from the start date
        const date = new Date(this.form.startDate)
        date.setDate(date.getDate() + 1)
        return date
      }

      return null
    },
    maxDate() {
      if (this.form.endDate) {
        // add 1 day to the end date
        const date = new Date(this.form.endDate)
        date.setDate(date.getDate() - 1)
        return date
      }

      return null
    },
  },
  watch: {
    advancedView(value) {
      this.isAdvancedView = value
    },
  },
  mounted() {
    this.isAdvancedView = this.advancedView
    this.showRange = this.product.start_date || this.product.end_date
    this.form = {
      fulfilmentBy: this.product.fulfilment_by || 'FBA',
      salesVatTax: this.product.sales_vat_tax || 0,
      salesVatTaxEnabled: this.product.sales_vat_tax_enabled || false,
      offsetVatOnFees: this.product.offset_vat_on_amazon_fee_rate || 0,
      offsetVatOnFeesEnabled: this.product.offset_vat_on_amazon_fee_rate_enabled || false,
      manufacturing: this.product.manufacturing || 0,
      inspection: this.product.inspection || 0,
      regionShipping: this.product.region_shipping || 0,
      importTax: this.product.import_tax || 0,
      otherProductCosts: this.product.other_cost || 0,
      shippingToAmazon: this.product.local_shipping || 0,
      fbmShippingPerUnit: this.product.fbm_shipping || 0,
      startDate: this.product.start_date || null,
      endDate: this.product.end_date || null,
      otherSkus: [],
    }
    this.currency = this.product.currency
  },
  methods: {
    onSaveBtnClick() {
      this.$emit('on-save', {
        ...this.form,
        settingsId: this.settingsId,
        asin: this.product.asin,
        sku: this.product.sku,
        marketplace: this.product.marketplace?.marketplace_id,
        isAdvancedView: this.isAdvancedView === true,
      })
    },
    onSelectionChanged(data) {
      this.form.otherSkus = data
    },
  },
}
</script>

<style lang="scss" scoped>
  .cost-form {
    .width-60 {
      width: 60%;
      max-width: 60%;
      min-width: 60%;
    }
    .form-label {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      min-width: 10rem;
      text-overflow: ellipsis;
    }
    .checkbox-wrapper {
      flex: 0 0 30px;
      width: 30px;
    }
    .input-wrapper {
      flex: 0 0 75px;
      width: 75px;
    }
    .input-wrapper-large {
      flex: 0 0 105px;
      width: 105px;
      text-align: right;
    }
    .text-bold {
      font-weight: bold;
    }
  }

</style>
