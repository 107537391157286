import ProductService from '@/api/product.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchProductsConfiguration({ commit }, params) {
      return ProductService.getProductsConfiguration(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchHistory({ commit }, params) {
      return ProductService.fetchHistory(params)
    },
    // eslint-disable-next-line no-unused-vars
    updateCostsSettings({ commit }, params) {
      return ProductService.updateCostsSettings(params)
    },
    // eslint-disable-next-line no-unused-vars
    createCostsSettings({ commit }, params) {
      return ProductService.createCostsSettings(params)
    },
    // eslint-disable-next-line no-unused-vars
    deleteCostsSettings({ commit }, params) {
      return ProductService.deleteCostsSettings(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchOtherSKUs({ commit }, params) {
      return ProductService.fetchOtherSKUs(params)
    },
    // eslint-disable-next-line no-unused-vars
    fetchImages({ commit }, params) {
      return ProductService.fetchImages(params)
    },
    // eslint-disable-next-line no-unused-vars
    setDefaultImage({ commit }, params) {
      return ProductService.setDefaultImage(params)
    },
  },
}
