import moment from 'moment'

export const isLocal = () => process.env.NODE_ENV === 'local'
export const apiBaseUrl = () => `${process.env.VUE_APP_API_SCHEME}://${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_BASE_PATH}`
export const wsBaseUrl = () => process.env.VUE_APP_WS_BASE_URL

export const findDateRangeKey = value => [
  {
    id: 'today',
    text: 'Today',
    singleDay: true,
  },
  {
    id: 'yesterday',
    text: 'Yesterday',
    singleDay: true,
  },
  {
    id: 'dayBeforeYesterday',
    text: 'Day Before Yesterday',
    singleDay: true,
  },
  {
    id: 'last7days',
    text: 'Last 7 Days',
    singleDay: false,
  },
  {
    id: 'thisMonth',
    text: 'This Month',
    singleDay: false,
  },
  {
    id: 'lastMonth',
    text: 'Last Month',
    singleDay: false,
  },
  {
    id: 'last30days',
    text: 'Last 30 Days',
    singleDay: false,
  },
  {
    id: 'last6months',
    text: 'Last 6 Months',
    singleDay: false,
  },
  {
    id: 'last12months',
    text: 'Last 12 Months',
    singleDay: false,
  },
  {
    id: 'lastYear',
    text: 'Last Year',
    singleDay: false,
  },
  {
    id: 'yearToDate',
    text: 'Year To Date',
    singleDay: false,
  },
  {
    id: 'allTime',
    text: 'All Time',
    singleDay: false,
  },
].find(item => item.text.toLowerCase() === value?.toLowerCase())?.id

export const smartDateTagToDateRange = value => {
  const ranges = {
    today: [
      moment().hour(0).minute(0).second(0)
        .toDate(),
      moment().hour(23).minute(59).second(59)
        .toDate(),
    ],
    yesterday: [
      moment().subtract(1, 'day').hour(0).minute(0)
        .second(0)
        .toDate(),
      moment().subtract(1, 'day').hour(23).minute(59)
        .second(59)
        .toDate(),
    ],
    dayBeforeYesterday: [
      moment().subtract(2, 'day').hour(0).minute(0)
        .second(0)
        .toDate(),
      moment().subtract(2, 'day').hour(23).minute(59)
        .second(59)
        .toDate(),
    ],
    last7days: [
      moment().subtract(7, 'day').hour(0).minute(0)
        .second(0)
        .toDate(),
      moment().hour(23).minute(59)
        .second(59)
        .toDate(),
    ],
    thisMonth: [
      moment().startOf('month').hour(0).minute(0)
        .second(0)
        .toDate(),
      moment().hour(23).minute(59)
        .second(59)
        .toDate(),
    ],
    lastMonth: [
      moment().subtract(1, 'month').startOf('month').hour(0)
        .minute(0)
        .second(0)
        .toDate(),
      moment().subtract(1, 'month').endOf('month').hour(23)
        .minute(59)
        .second(59)
        .toDate(),
    ],
    last30days: [
      moment().subtract(30, 'day').hour(0).minute(0)
        .second(0)
        .toDate(),
      moment().hour(23).minute(59)
        .second(59)
        .toDate(),
    ],
    last6months: [
      moment().subtract(7, 'month').startOf('month').hour(0)
        .minute(0)
        .second(0)
        .toDate(),
      moment().subtract(1, 'month').endOf('month').hour(23)
        .minute(59)
        .second(59)
        .toDate(),
    ],
    last12months: [
      moment().subtract(13, 'month').startOf('month').hour(0)
        .minute(0)
        .second(0)
        .toDate(),
      moment().subtract(1, 'month').endOf('month').hour(23)
        .minute(59)
        .second(59)
        .toDate(),
    ],
    lastYear: [
      moment().subtract(1, 'year').startOf('year').hour(0)
        .minute(0)
        .second(0)
        .toDate(),
      moment().subtract(1, 'year').endOf('year').hour(23)
        .minute(59)
        .second(59)
        .toDate(),
    ],
    yearToDate: [
      moment().startOf('year').hour(0).minute(0)
        .second(0)
        .toDate(),
      moment().hour(23).minute(59)
        .second(59)
        .toDate(),
    ],
    allTime: [
      moment('2010-01-01', 'YYYY-MM-DD').toDate(),
      moment().hour(0).minute(0).second(0)
        .toDate(),
    ],
  }
  return ranges[value] ?? []
}
