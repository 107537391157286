/* eslint-disable no-unused-vars */
import Service from '@/api/subscription.service'

export default {
  namespaced: true,
  actions: {
    fetchCurrentSubscription({ commit }, params) {
      return Service.getCurrentSubscriptionPlan(params)
    },
    fetchAvailableSubscription({ commit }, params) {
      return Service.getAvailableSubscription(params)
    },
    subscribe({ commit }, params) {
      return Service.getCheckoutUrl(params)
    },
    fetchInvoices({ commit }, params) {
      return Service.getInvoices(params)
    },
    fetchPaymentMethods({ commit }, params) {
      return Service.getPaymentMethods(params)
    },
    changePaymentMethod({ commit }, params) {
      return Service.updatePaymentMethod(params)
    },
    cancelSubscription({ commit }, params) {
      return Service.cancelSubscription(params)
    },
    fetchSubscriptionPlans({ commit }, params) {
      return Service.getSubscriptionPlans(params)
    },
  },
}
