import BaseService from '@/api/base.service'
import api from '@/api/http'

export class ShopsService extends BaseService {
  constructor() {
    super({ root: 'shops', api })
  }

  fetchList(payload) {
    return this.invoke('find', payload, 'get')
  }

  syncSkippedOrders(payload) {
    return this.invoke('sync-skipped-orders', payload, 'post')
  }
}

export default new ShopsService()
