import api from './http'

class DashboardService {
    root = 'dashboard';

    sales(params) {
      return this.invoke('sales', params)
    }

    salesGrowth(params) {
      return this.invoke('sales-growth', params)
    }

    margin(params) {
      return this.invoke('margin', params)
    }

    recordUnitSold(params) {
      return this.invoke('record-unit-sold', params)
    }

    recordProfit(params) {
      return this.invoke('record-profit', params)
    }

    topSellers(params) {
      return this.invoke('top-sellers', params)
    }

    inventoryStock(params) {
      return this.invoke('inventory-stock', params)
    }

    inventoryStatus(params) {
      return this.invoke('inventory-status', params)
    }

    sellersActivity(params) {
      return this.invoke('sellers-activity', params)
    }

    salesForecast() {
      return this.invoke('sales-forecast')
    }

    salesTrends(params) {
      return this.invoke('sales-trends', params)
    }

    invoke(action, params) {
      return api.get(`/${this.root}/${action}`, { params }).then(({ data }) => data.payload)
    }
}

export default new DashboardService()
