import { Ability } from '@casl/ability'
import { initialAbility } from './config'

export const loadAbility = () => {
  // TODO load from vuex store
  const userData = JSON.parse(localStorage.getItem('user'))
  const existingAbility = userData ? userData.ability : null
  return new Ability(existingAbility || initialAbility)
}

export default loadAbility
