/* eslint-disable no-param-reassign,no-unused-vars */
import { $themeBreakpoints } from '@themeConfig'

const appSettings = JSON.parse(localStorage.getItem('app-settings'))
const initialState = () => {
  const identity = JSON.parse(localStorage.getItem('user'))
  const state = {
    windowWidth: 0,
    shallShowOverlay: false,
    availableMarketplaces: [],
    appSettings: appSettings ?? {},
  }
  if (identity && identity.marketplaces) {
    state.availableMarketplaces = identity.marketplaces
  }
  return state
}
export default {
  namespaced: true,
  state: initialState(),
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    availableMarketplaceOptions: state => state.availableMarketplaces.map(marketplace => ({ title: marketplace.country, id: marketplace.marketplace_id, country: marketplace.country_code })),
    availableMarketplaceIds: state => state.availableMarketplaces.map(marketplace => marketplace.marketplace_id),
    availableMarketplaces: state => state.availableMarketplaces,
    getSettingsById: state => id => {
      if (state.appSettings[id]) {
        return state.appSettings[id]
      }
      return undefined
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_AVAILABLE_MARKETPLACES(state, val) {
      state.availableMarketplaces = val
    },
    UPDATE_APP_SETTINGS(state, { index, value }) {
      state.appSettings[index] = value
      localStorage.setItem('app-settings', JSON.stringify(state.appSettings))
    },
  },
  actions: {},
}
