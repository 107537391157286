import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import { mapState } from 'vuex'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import EventBus from '@/utils/event-bus'

import setupInterceptors from './api/setupInterceptors'
import { wsBaseUrl } from '@/utils/utils'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

setupInterceptors(store)

Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketIO(wsBaseUrl(), {
    autoConnect: false,
  }),
}))

new Vue({
  router,
  store,
  sockets: {
    connect() {
      this.$socket.onevent = this.onWebSocketEvent
    },
  },
  computed: {
    ...mapState({
      isUserLoggedIn: state => state.auth.status.loggedIn,
    }),
  },
  watch: {
    isUserLoggedIn(value) {
      if (value) {
        this.connectToSocket()
      } else {
        this.$socket.close()
      }
    },
  },
  created() {
    if (!store.getters['auth/userId']) {
      this.$socket.close()
    } else {
      this.connectToSocket()
    }

    EventBus.$on('ws_notification', this.onWsNotification)
  },
  methods: {
    connectToSocket() {
      this.$socket.auth = { uuid: store.getters['auth/userId'] }
      this.$socket.connect()
    },
    onWebSocketEvent({ data }) {
      const [eventName, args] = data
      EventBus.$emit(eventName, args)
    },
    onWsNotification({ title, message, type }) {
      const typeMapping = {
        alert: 'danger',
        warning: 'warning',
        info: 'info',
        success: 'success',
      }
      this.$bvToast.toast(message, {
        title: title || 'Notification',
        variant: typeMapping[type] || 'primary',
        solid: true,
      })
    },
  },
  render: h => h(App),
}).$mount('#app')
