import api from '@/api/http'

class FilesService {
    root = 'files';

    upload(file) {
      const formData = new FormData()
      formData.append('file', file)
      return api.post(`/${this.root}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(({ data }) => data.payload || undefined)
    }

    download(payload) {
      return api.get(`/${this.root}/download`, {
        params: {
          file_id: payload.file_id,
        },
        responseType: 'blob',
      }).then(response => response)
    }
}

export default new FilesService()
