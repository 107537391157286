import SellersService from '../../api/sellers.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchList({ commit }, params) {
      return SellersService.list(params)
    },
    // eslint-disable-next-line no-unused-vars
    export({ commit }, params) {
      return SellersService.export(params)
    },
  },
}
