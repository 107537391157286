/* eslint-disable no-unused-vars */
import Service from '@/api/user.service'

export default {
  namespaced: true,
  actions: {
    changePassword({ commit }, payload) {
      return Service.changePassword(payload)
    },
    updateProfile({ commit }, payload) {
      return Service.updateProfile(payload)
    },
    updateSettings({ commit }, payload) {
      return Service.updateSettings(payload)
    },
    getSettings({ commit }, key) {
      return Service.getSettings(key)
    },
    getShopStatus({ commit }) {
      return Service.getShopStatus()
    },
    getShopSyncStatus({ commit }) {
      return Service.getShopSyncStatus()
    },
    fetchList({ commit }, payload) {
      return Service.getList(payload)
    },
    find({ commit }, payload) {
      return Service.find(payload)
    },
    approveSignUp({ commit }, payload) {
      return Service.approveSignUp(payload)
    },
    sendShopNotConnectedReminder({ commit }, payload) {
      return Service.sendShopNotConnectedReminder(payload)
    },
    fetchLedgers({ commit }, payload) {
      return Service.fetchLedgers(payload)
    },
  },
}
