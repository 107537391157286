import ProfitAndLossService from '@/api/profit-and-loss.service'

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchData({ commit }, params) {
      return ProfitAndLossService.fetchData(params)
    },
  },
}
