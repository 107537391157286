/* eslint-disable no-unused-vars */
import Service from '@/api/shops.service'

export default {
  namespaced: true,
  actions: {
    fetchList({ commit }, payload) {
      return Service.fetchList(payload)
    },
    syncSkippedOrders({ commit }, payload) {
      return Service.syncSkippedOrders(payload)
    },
  },
}
