export default class BaseService {
  constructor({ root, api }) {
    this.root = root
    this.api = api
  }

  invoke(action, payload, method = 'get') {
    if (method === 'get') {
      return this.api.request({
        url: `/${this.root}/${action}`,
        method,
        params: payload,
      }).then(({ data }) => data.payload)
    }

    return this.api.request({
      url: `/${this.root}/${action}`,
      method,
      data: payload,
    }).then(({ data }) => data.payload)
  }
}
