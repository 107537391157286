<template>
  <div
    v-if="hasData"
    class="d-flex flex-column"
  >
    <div class="tw-text-sm tw-mb-2">
      Apply same cost to other listings
    </div>
    <DxDataGrid
      id="other-skus-grid"
      ref="gridView"
      class="sxr-grid"
      width="100%"
      height="300"
      :data-source="datasource"
      :show-borders="false"
      :show-row-lines="true"
      :show-column-lines="true"
      :remote-operations="true"
      :word-wrap-enabled="true"
      :no-data-text="noResultsFoundMessage"
      :column-auto-width="true"
      :allow-column-reordering="false"
      :allow-column-resizing="false"
      :on-selection-changed="onSelectionChanged"
    >
      <DxPaging :enabled="false" />
      <DxSelection
        mode="multiple"
        show-check-boxes-mode="always"
      />
      <DxColumn
        :allow-editing="false"
        :show-in-column-chooser="false"
        :allow-hiding="false"
        data-field="image"
        caption=""
        data-type="string"
        :allow-resizing="false"
        :allow-sorting="false"
        :fixed="true"
        css-class="fixed-column-cell"
        fixed-position="left"
        :min-width="60"
        :max-width="60"
        :width="60"
        cell-template="imageCell"
      />
      <DxColumn
        :allow-editing="false"
        :show-in-column-chooser="false"
        :allow-exporting="false"
        :allow-sorting="false"
        data-field="marketplace"
        caption=""
        data-type="string"
        :fixed="true"
        :allow-resizing="false"
        css-class="fixed-column-cell"
        fixed-position="left"
        :min-width="50"
        :max-width="50"
        :width="50"
        alignment="center"
        :render-async="true"
        cell-template="marketplaceCell"
      />
      <DxColumn
        :allow-editing="false"
        :show-in-column-chooser="false"
        :allow-sorting="false"
        :allow-hiding="false"
        data-field="asin"
        caption="ASIN"
        data-type="string"
        :allow-resizing="false"
        :fixed="true"
        css-class="fixed-column-cell"
        fixed-position="left"
        :min-width="140"
        :max-width="140"
        :width="140"
      />
      <DxColumn
        :allow-editing="false"
        :show-in-column-chooser="false"
        :allow-sorting="false"
        :allow-hiding="false"
        data-field="sku"
        caption="SKU"
        data-type="string"
        :allow-resizing="false"
        :fixed="true"
        css-class="fixed-column-cell"
        fixed-position="left"
        :min-width="180"
        :max-width="180"
        :width="180"
      />
      <template #imageCell="{ data }">
        <div class="flex flex-column text-center">
          <b-avatar
            rounded
            :src="data.data.image"
            :alt="data.data.sku"
            :title="data.data.product_name"
          >
            <feather-icon
              v-if="isEmpty(data.data.image)"
              size="24"
              icon="ImageIcon"
            />
          </b-avatar>
        </div>
      </template>
      <template #marketplaceCell="{ data }">
        <CountryFlag
          :key="data.data.marketplace.country_code"
          :country="data.data.marketplace.country_code"
          :title="data.data.marketplace.country"
          :alt="data.data.marketplace.country"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxColumn, DxDataGrid, DxPaging, DxSelection,
} from 'devextreme-vue/data-grid'
import { BAvatar } from 'bootstrap-vue'
import CountryFlag from 'vue-country-flag'

export default {
  name: 'OtherSKUs',
  components: {
    DxPaging,
    BAvatar,
    DxColumn,
    DxDataGrid,
    CountryFlag,
    DxSelection,
  },
  props: {
    asin: {
      type: String,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    marketplace: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      datasource: [],
      noResultsFoundMessage: 'No data found',
    }
  },
  computed: {
    hasData() {
      return this.datasource.length > 0
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
  },
  mounted() {
    this.$store.dispatch('product/fetchOtherSKUs', { asin: this.asin, marketplace: this.marketplace })
      .then(response => {
        this.datasource = []
        if (response.total_items) {
          this.datasource = response.items.filter(item => !(item.sku === this.sku && item.marketplace.marketplace_id === this.marketplace && item.asin === this.asin))
        }
      })
  },
  methods: {
    isEmpty(value) {
      return !value || value === 'null' || value === 'undefined' || value.trim() === ''
    },
    onSelectionChanged(e) {
      const selectedRows = e.selectedRowsData
      this.$emit('on-selection-changed', selectedRows.map(row => ({ sku: row.sku, marketplace: row.marketplace.marketplace_id })))
    },
  },
}

</script>

<style scoped lang="scss">

</style>
