import api from '@/api/http'

class ProductService {
    root = 'products';

    rootProduct = 'product';

    // eslint-disable-next-line class-methods-use-this
    getProductsConfiguration(params) {
      const config = {
        url: '/products/cost-settings/find',
        method: 'POST',
        data: params,
      }

      return api.request(config).then(({ data }) => data.payload)
    }

    updateCostsSettings(params) {
      return this.invoke('cost-settings/update', params, 'POST')
    }

    createCostsSettings(params) {
      return this.invoke('cost-settings/add', params, 'POST')
    }

    deleteCostsSettings(params) {
      return this.invoke('cost-settings/delete', params, 'POST')
    }

    fetchHistory(params) {
      return this.invoke('cost-settings/history', params, 'GET')
    }

    invoke(action, params, method = 'GET') {
      const config = {
        url: `/${this.root}/${action}`,
        method,
      }

      if (method === 'GET') {
        config.params = params
      } else {
        config.data = params
      }

      return api.request(config).then(({ data }) => data.payload)
    }

    fetchOtherSKUs(params) {
      return this.invoke('cost-settings/find-products-by-asin', params, 'GET')
    }

    fetchImages(params) {
      return api.request({
        url: `/${this.rootProduct}/get-images`,
        method: 'GET',
        params,
      }).then(({ data }) => data.payload)
    }

    setDefaultImage(params) {
      return api.request({
        url: `/${this.rootProduct}/set-default-image`,
        method: 'POST',
        data: params,
      }).then(({ data }) => data.payload)
    }
}

export default new ProductService()
