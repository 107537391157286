<template>
  <div class="tw-flex tw-flex-row tw-justify-between tw-items-end tw-p-2">
    <div class="d-flex flex-column product-details">
      <div class="d-flex flex-row">
        <div class="label">
          ASIN:
        </div>
        <div>&nbsp;{{ asin }}</div>
      </div>
      <div class="d-flex flex-row">
        <div class="label">
          SKU:
        </div>
        <div>&nbsp;{{ sku }}</div>
      </div>
      <div class="d-flex flex-row">
        <div class="label">
          Short Name:
        </div>
        <div>&nbsp;{{ shortName }}</div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <div class="position-relative">
        <b-avatar
          rounded
          size="64px"
          :src="image"
          :alt="asin"
        >
          <feather-icon
            v-if="isEmpty(image)"
            size="24"
            icon="ImageIcon"
          />
        </b-avatar>
        <CountryFlag
          :key="countryCode"
          class="product-marketplace"
          :country="countryCode"
          :title="country"
          :alt="country"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import CountryFlag from 'vue-country-flag'

export default {
  name: 'ProductDetails',
  components: {
    BAvatar,
    CountryFlag,
  },
  props: {
    asin: {
      type: String,
      default: undefined,
    },
    sku: {
      type: String,
      default: undefined,
    },
    shortName: {
      type: String,
      default: undefined,
    },
    image: {
      type: String,
      default: undefined,
    },
    marketplace: {
      type: Object,
      require: false,
      default: () => ({}),
    },
  },
  computed: {
    countryCode() {
      return this.marketplace?.country_code || ''
    },
    country() {
      return this.marketplace?.country || ''
    },
  },
  methods: {
    isEmpty(value) {
      return !value || value === null || value === undefined || value.trim() === ''
    },
  },
}
</script>

<style lang="scss" scoped>
.product-details {
  .label {
    font-weight: 800;
  }
}
.product-marketplace {
  position: absolute;
  right: -17px;
  bottom: -2px;
}
</style>
