import api from '@/api/http'
import BaseService from '@/api/base.service'

class SystemSettingsService extends BaseService {
  constructor() {
    super({ root: 'settings', api })
  }

  getSettings() {
    return this.invoke('get', {})
  }

  fetchAppConf(payload) {
    return this.invoke('conf', payload)
  }

  saveStripeSettings(payload) {
    return this.invoke('save-stripe-settings', payload, 'POST')
  }

  saveGeneralSettings(payload) {
    return this.invoke('save-general-settings', payload, 'POST')
  }

  saveSettingsByKey(payload) {
    return this.invoke('save-settings-by-key', payload, 'POST')
  }

  invoke(action, params, method = 'GET') {
    const config = {
      url: `/${this.root}/${action}`,
      method,
    }

    if (method === 'GET') {
      config.params = params
    } else {
      config.data = params
    }

    return api.request(config).then(({ data }) => data.payload)
  }
}

export default new SystemSettingsService()
