import api from '@/api/http'

export class SubscriptionService {
    root = 'subscription';

    getCurrentSubscriptionPlan(params) {
      return api.get(`/${this.root}/get-current-subscription-plan`, { params }).then(({ data }) => data.payload)
    }

    getAvailableSubscription(params) {
      return api.get(`/${this.root}/get-available-plan`, { params }).then(({ data }) => data.payload)
    }

    getCheckoutUrl(params) {
      return api.get(`/${this.root}/checkout`, { params }).then(({ data }) => data.payload)
    }

    getInvoices(params) {
      return api.get(`/${this.root}/invoices`, { params }).then(({ data }) => data.payload)
    }

    getPaymentMethods(params) {
      return api.get(`/${this.root}/payment-methods`, { params }).then(({ data }) => data.payload)
    }

    updatePaymentMethod(params) {
      return api.get(`/${this.root}/update-payment-method`, { params }).then(({ data }) => data.payload)
    }

    cancelSubscription() {
      return api.delete(`/${this.root}/cancel`).then(({ data }) => data.payload)
    }

    getSubscriptionPlans(params) {
      return api.get(`/${this.root}/get-plans`, { params }).then(({ data }) => data.payload)
    }
}

export default new SubscriptionService()
