/* eslint-disable */
import axiosInstance from './http'
import TokenService from './token.service'
import router from '@/router';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

const setup = store => {
  axiosInstance.interceptors.request.use(
    config => {
      const token = TokenService.getLocalAccessToken()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    error => Promise.reject(error),
  )

  axiosInstance.interceptors.response.use(
    res => res,
    async err => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/sign-in' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {

            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({resolve, reject})
                }).then(token => {
                    originalConfig.headers['Authorization'] = 'Bearer ' + token;
                    return axiosInstance(originalConfig);
                }).catch(err => {
                    return Promise.reject(err);
                })
            }

          originalConfig._retry = true
          isRefreshing = true;

          try {
            const rs = await axiosInstance.post('/auth/refresh', {
              refresh_token: TokenService.getLocalRefreshToken(),
            })

            const { access_token, refresh_token } = rs.data.payload

            store.dispatch('auth/refreshToken', access_token)
            TokenService.setAccessTokens(rs.data.payload)
            processQueue(null, refresh_token);
            isRefreshing = false;
            return axiosInstance(originalConfig)
          } catch (_error) {
            isRefreshing = false;
            return Promise.reject(_error)
          }
        } else if( err.response.status === 403 && originalConfig.url === '/auth/refresh') {
            store.dispatch('auth/logout')
            if (router.currentRoute.name !== 'auth-login') {
                await router.replace({ name: 'auth-login' })
            }
        } else if( err.response.status === 402) {
            store.commit('auth/subscription', false)
            if (router.currentRoute.name !== 'dashboard') {
                await router.replace({ name: 'dashboard' })
            }
        }
      }

      return Promise.reject(err)
    },
  )
}

export default setup
