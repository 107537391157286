import api from '@/api/http'
import BaseService from '@/api/base.service'

class InvitationRequestsService extends BaseService {
  constructor() {
    super({ root: 'invitation-request', api })
  }

  fetchList(payload) {
    return this.invoke('', payload)
  }

  approve(payload) {
    return this.invoke('approve', payload, 'POST')
  }

  invoke(action, params, method = 'GET') {
    const config = {
      url: `/${this.root}/${action}`,
      method,
    }

    if (method === 'GET') {
      config.params = params
    } else {
      config.data = params
    }

    return api.request(config).then(({ data }) => data.payload)
  }
}

export default new InvitationRequestsService()
