<template>
  <div class="d-flex flex-column">
    <h5>Estimate FBA Profits <span v-if="currency">({{ currency }})</span></h5>
    <div class="components">
      <div class="component">
        <div class="component-name">
          List Price
        </div>
        <div class="component-value">
          22.05
        </div>
      </div>
      <div class="component">
        <div class="component-name">
          Tax/VAT
        </div>
        <div class="component-value">
          0
        </div>
      </div>
      <div class="component">
        <div class="component-name">
          FBA COGS
        </div>
        <div class="component-value">
          0
        </div>
      </div>
      <div class="component">
        <div class="component-name">
          Referral Fees
        </div>
        <div class="component-value">
          0
        </div>
      </div>
      <div class="component">
        <div class="component-name">
          FBA Fees
        </div>
        <div class="component-value">
          0
        </div>
      </div>
      <div class="component">
        <div class="component-name">
          Amazon Fees tax offset
        </div>
        <div class="component-value">
          0
        </div>
      </div>
      <hr>
      <div class="component">
        <div class="component-name">
          Unit Profit <span v-if="currency">({{ currency }})</span>
        </div>
        <div class="component-value">
          0
        </div>
      </div>
      <div class="component">
        <div class="component-name">
          Unit Profit (USD)
        </div>
        <div class="component-value">
          0
        </div>
      </div>
      <div class="component">
        <div class="component-name">
          Profit Margin
        </div>
        <div class="component-value">
          0%
        </div>
      </div>
      <div class="component">
        <div class="component-name">
          ROI
        </div>
        <div class="component-value">
          0%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EstimateFBAProfit',
  props: {
    currency: {
      type: String,
      default: undefined,
    },
  },
}
</script>

<style lang="scss" scoped>
  .components {
    display: flex;
    flex-flow: column;
    .component {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      .component-name {
        flex: 1 1 auto;
      }
    }
  }
</style>
